import React from "react"
import { Heading } from "@chakra-ui/core"

type Props = {}

export const Heading3: React.FC<Props> = ({ children }) => {
  return (
    <Heading
      as="h4"
      textAlign="center"
      fontSize={["0.7rem", "0.8rem", "0.9rem", "1.2rem"]}
      paddingX={["1rem", "3rem"]}
      fontWeight="bold"
      letterSpacing="0.3rem"
      textTransform="uppercase"
      color="black"
      paddingY="2rem"
    >
      {children}
    </Heading>
  )
}
